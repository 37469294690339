interface Slider {
    currentSlide: number
    readonly count: number
    readonly slideWidth: number
    syncCurrentToScroll(): void
    prev(): void
    next(): void
}

export default function (): Slider {
    return {
        currentSlide: 1,
        get count() {
            const slider = this.$refs.slider as HTMLElement
            return slider?.childElementCount ?? 0
        },
        get slideWidth() {
            const slider = this.$refs.slider as HTMLElement
            return slider?.firstElementChild?.clientWidth ?? 0
        },
        syncCurrentToScroll(){
            const slider: HTMLElement = this.$refs.slider
            this.currentSlide = Math.max(1, Math.round(slider.scrollLeft / Math.max(this.slideWidth, 1)) + 1)
        },
        prev() { this.$refs.slider?.scrollBy(-1, 0) },
        next() { this.$refs.slider?.scrollBy(1, 0) },
    }
}
